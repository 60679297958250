<template>
  <div class="flex flex-col flex-grow">
    <div class="overflow-auto border border-gray-700 rounded-md shadow-md mx-8 mt-8 p-2 text-left">
      <editor-content :editor="editor" />
    </div>
    <div class="mt-8 h-16 bg-gray-100 rounded-md shadow-md border border-gray-300 text-left mx-8 px-4">
      <button class="save-note" @click="$store.dispatch('saveNote')">Save</button>
    </div>
  </div>
</template>
<script>
import { EditorContent } from "@tiptap/vue-3";
export default {
  components: {
    EditorContent,
  },
  computed: {
      editor() {
          return this.$store.state.editor;
      }
  }
};
</script> 